module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-G6BNHBJVKH"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Health Impact Fund","short_name":"HIF","start_url":"/","background_color":"#80cf0f","theme_color":"#80cf0f","display":"minimal-ui","icon":"src/images/hif-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"70fd34f10a9acbe0b4b06d0cac5a8b5e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"C:\\Code\\websites\\Health-Impact-Fund/src/intl","languages":["ar","zh","en","fr","de","ha","it","ja","ko","pt","es","tr"],"defaultLanguage":"en","redirect":true,"redirectComponent":"C:\\Code\\websites\\Health-Impact-Fund\\src\\components\\redirect.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
